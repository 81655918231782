import React, { FC, ReactElement } from 'react';

import { Wrapper } from '../components';
import { LinkIconButton } from '../compositions';
import { ConnectedPage } from '../connectors';
import trans from '../helpers/trans';

import './404.scss';

const NotFound: FC = (): ReactElement => {
    const metaData = {
        title: trans('pages.notFound.meta.title'),
        description: trans('pages.notFound.meta.description'),
    };

    return (
        <ConnectedPage metaData={metaData} className="not-found-page">
            <Wrapper className="not-found-page__wrapper">
                <h1 className="not-found-page__title">
                    {trans('pages.notFound.title')}
                </h1>

                <h2 className="not-found-page__subtitle">
                    {trans('pages.notFound.subtitle')}
                </h2>

                <p className="not-found-page__description">
                    {trans('pages.notFound.description')}
                </p>

                <LinkIconButton
                    to="/"
                    icon="chevron-left"
                    text={trans('pages.notFound.linkButton')}
                    className="not-found-page__link-button"
                />
            </Wrapper>
        </ConnectedPage>
    );
};

export default NotFound;
